import { time } from "console";
import React from "react";

import {
  IBreakpoint,
  IBuilding,
  IClient,
  IDataContidion,
  ILayoutItem,
  IWidgetProp,
} from "@/lib/interfaces";
import { removeLayoutItem } from "@/lib/reducers/dashboard";
import { AppDispatch } from "@/lib/store";
import {
  CustomTooltipProps,
  ReactSelectListType,
  WidgetPreviewType,
} from "@/lib/types";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { getUnixTime } from "date-fns";
import { lowerCase } from "lodash";
import Image from "next/image";

import { DataGridTdBorder, DataGridTextFilter, DataGridTh } from "../colors";
import { SensorUnit } from "../modules/Devices";

export type DynamicWidgetTypes = {
  onClose?: () => void;
  componentName: string;
  componentId?: string;
  preview?: boolean;
  newWidget?: IWidgetProp;
};

export const WidgetPreview: React.FC<WidgetPreviewType> = ({
  image,
  description,
}) => {
  return (
    <Tooltip
      hasArrow
      placement="left"
      label={description}
      aria-label={description}
    >
      <Flex flexDirection="column" alignItems="center" h="100%" w="100%">
        <Flex w="100%" h="100%" justifyContent="center">
          <Image width={30} height={30} src={image} alt="WidgetPreview" />
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export const CustomToolTip = ({
  children,
  label,
  placement = "top",
  bg = "black",
  color,
  display = "flex",
  isLoading = false,
}: CustomTooltipProps) => {
  const [fontSize] = useToken("fontSizes", ["md"]);
  return (
    <Tooltip
      fontSize={fontSize}
      shadow={"md"}
      display={display}
      color={color ? color : "#fff"}
      bg={bg}
      hasArrow
      placement={placement}
      label={label}
    >
      {children}
    </Tooltip>
  );
};

export function getFavBuilding(
  buildings: Array<IBuilding>,
  type: string,
  currentBuilding: string | undefined
): string {
  let favId: string = "";
  if (currentBuilding) {
    buildings.map((item) => {
      if (item.type === type) {
        currentBuilding.split("_").map((bId) => {
          if (bId === item.id) {
            favId = item.id;
          }
        });
      }
    });
  }

  return favId;
}

export function GetBreakpoint() {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
    "2xl": "2xl",
  });
  return variant;
}

export function IsLargeOrBigger(breakpoint: IBreakpoint): boolean {
  if (breakpoint === "lg" || breakpoint === "xl" || breakpoint === "2xl") {
    return true;
  }

  return false;
}

export function isMobile(breakpoint: IBreakpoint): boolean {
  if (breakpoint === "base" || breakpoint === "sm") {
    return true;
  }

  return false;
}

export function ucfirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getTimestampUTC(date = new Date()): string {
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return getUnixTime(utcDate).toString();
}

export function checkFilesLables(files, setFiles): bolean {
  let error = false;
  if (files.length > 0) {
    files.map((file, index) => {
      if (file.label === "") {
        error = true;
      }
      const newFiles = [...files];
      newFiles[index].isInvalid = file.label === "";
      setFiles(newFiles);
    });
  }
  return error;
}

export async function fileUploadHelper(
  promises: Array<Promise<Blob>>,
  files: Array<any>,
  fileUpload: (arg0: any) => Promise<Blob>,
  client: string,
  callback: (fileHash: string, fileName: string, fileLabel: string) => void
) {
  await (
    await Promise.all(promises)
  ).map(async (blob, index) => {
    const file = files[index];
    var reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onloadend = async function () {
      const content = reader.result; //base64
      const mime = ""; //mimeType
      // const mime = file.file.data[0].type; //mimeType

      const fileUploadResult = await fileUpload({
        url: `/client/${client}/file`,
        body: {
          name: file.fileName,
          mime,
          content: content as string,
        },
      });

      if (fileUploadResult.data.status === "success") {
        const fileHash = fileUploadResult.data.data.hash;
        callback(fileHash, file.fileName, file.label);
      }
    };
  });
}

type ItemsTableProps = {
  columns: Array<string>;
  values: Array<any>;
};

export const ItemsTable = ({ columns, values }: ItemsTableProps) => {
  return (
    <Table>
      <Thead
      // borderBottom={`solid 1px ${DataGridTextFilter()}`}
      >
        <Tr m={1}>
          {columns.map((column, index) => (
            <Th
              // color='#fff'
              p={4}
              // bg={'#111E34'}
              borderRight={
                columns.length - 1 === index
                  ? "none"
                  : `solid 1px ${DataGridTh()}`
              }
              borderBottom="none"
              key={index}
            >
              {column}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {values.map((items, index) => {
          return (
            <Tr key={index} borderBottom="none">
              {Object.values(items).map((item, tdIndex) => {
                return (
                  <Td
                    key={tdIndex}
                    p={4}
                    borderBottom="none"
                    // bg='#1A1C1E'
                    borderRight={
                      columns.length - 1 === index
                        ? "none"
                        : `solid 1px ${DataGridTdBorder()}`
                    }
                  >
                    {item}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export function fileDownload(fileName: string, fileContent: string): void {
  const downloadLink = document.createElement("a");
  downloadLink.href = fileContent;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function floatRegex(value: string) {
  const regex = /[+-]?([0-9]*[.])?[0-9]+/;
  if (value === "" || regex.test(value)) {
    return true;
  }
  return false;
}

export function intRegex(value: string) {
  const regex = /^[0-9\b]+$/;
  if (value === "" || regex.test(value)) {
    return true;
  }
  return false;
}

export function getReactSelectOptions(
  childrenItems: Array<any>,
  parentItems: Array<any>,
  compareKey: string
) {
  const tempList: Array<ReactSelectListType> = [];

  parentItems.map((parent) => {
    childrenItems.map((child) => {
      if (child[compareKey] === parent.id) {
        const find = tempList.find((item) => item.parentId === parent.id);
        if (!find) {
          tempList.push({
            parentId: parent.id,
            label: parent.name,
            options: [
              {
                value: child.id as string,
                label: child.name,
              },
            ],
          });
        } else {
          find.options.push({
            value: child.id as string,
            label: child.name,
          });
        }
      }
    });
  });
  return tempList;
}

export function setModalData(
  lc: IDataContidion[],
  apiData: any[],
  setData: React.Dispatch<React.SetStateAction<any[]>>
) {
  if (lc.length > 0) {
    const tempData: any[] = [];
    apiData?.map((item) => {
      let add = true;
      lc?.map((condition) => {
        const property = Object.keys(condition)[0];
        if (item[property] !== condition[property]) {
          add = false;
        }
      });
      if (add) {
        tempData.push(item);
      }
    });
    setData(tempData);
  } else {
    setData(apiData);
  }
}

/**
 *
 * @param hex standard hex color
 * @param opacity  percentage 0-100
 * @returns hex color with opacity
 */
export function getHexOpacity(hex: string, opacity: number): string {
  const opacityArray = [
    [100, "FF"],
    [99, "FC"],
    [98, "FA"],
    [97, "F7"],
    [96, "F5"],
    [95, "F2"],
    [94, "F0"],
    [93, "ED"],
    [92, "EB"],
    [91, "E8"],
    [90, "E6"],
    [89, "E3"],
    [88, "E0"],
    [87, "DE"],
    [86, "DB"],
    [85, "D9"],
    [84, "D6"],
    [83, "D4"],
    [82, "D1"],
    [81, "CF"],
    [80, "CC"],
    [79, "C9"],
    [78, "C7"],
    [77, "C4"],
    [76, "C2"],
    [75, "BF"],
    [74, "BD"],
    [73, "BA"],
    [72, "B8"],
    [71, "B5"],
    [70, "B3"],
    [69, "B0"],
    [68, "AD"],
    [67, "AB"],
    [66, "A8"],
    [65, "A6"],
    [64, "A3"],
    [63, "A1"],
    [62, "9E"],
    [61, "9C"],
    [60, "99"],
    [59, "96"],
    [58, "94"],
    [57, "91"],
    [56, "8F"],
    [55, "8C"],
    [54, "8A"],
    [53, "87"],
    [52, "85"],
    [51, "82"],
    [50, "80"],
    [49, "7D"],
    [48, "7A"],
    [47, "78"],
    [46, "75"],
    [45, "73"],
    [44, "70"],
    [43, "6E"],
    [42, "6B"],
    [41, "69"],
    [40, "66"],
    [39, "63"],
    [38, "61"],
    [37, "5E"],
    [36, "5C"],
    [35, "59"],
    [34, "57"],
    [33, "54"],
    [32, "52"],
    [31, "4F"],
    [30, "4D"],
    [29, "4A"],
    [28, "47"],
    [27, "45"],
    [26, "42"],
    [25, "40"],
    [24, "3D"],
    [23, "3B"],
    [22, "38"],
    [21, "36"],
    [20, "33"],
    [19, "30"],
    [18, "2E"],
    [17, "2B"],
    [16, "29"],
    [15, "26"],
    [14, "24"],
    [13, "21"],
    [12, "1F"],
    [11, "1C"],
    [10, "1A"],
    [9, "17"],
    [8, "14"],
    [7, "12"],
    [6, "0F"],
    [5, "0D"],
    [4, "0A"],
    [3, "08"],
    [2, "05"],
    [1, "03"],
    [0, "00"],
  ];
  const findOpacity = opacityArray.find(
    (item) => item[0] === +opacity.toFixed(0)
  );

  return `${hex}${findOpacity ? findOpacity[1] : "FF"}`;
}

//currency

export function getCurrencySymbolByCode(code: string): string {
  const currencySymbols = {
    GBP: "£",
    USD: "$",
    EUR: "€",
    AUD: "$",
    "": "GBP", //default?
  };
  return currencySymbols[code] ? currencySymbols[code] : "";
}

export const nl2br = (str: string) => {
  const textArray = str.split("\n");
  return textArray.map((text, index) => {
    if (text == "") return null;
    return (
      <span style={{ marginBottom: "5px", display: "block" }} key={index}>
        {text}
        <br />
      </span>
    );
  });
};

export function getDisabledSuppliers(suppliers: IClient[]) {
  return suppliers
    ?.filter(
      (item) => item?.activeSupplier == "" || item?.activeSupplier == "0"
    )
    .map((item) => item.id);
}

export function convertTemperature(
  userTempUnit: "°C" | "°F" | "" | null | undefined | string,
  sensorTempUnit: SensorUnit,
  value: number
) {
  if (!userTempUnit) return value;

  const userUnit = lowerCase(userTempUnit)?.replace("°", "");
  const sensorUnit = lowerCase(sensorTempUnit)?.replace("°", "");

  // console.log(userUnit, sensorUnit);

  if (userUnit == sensorUnit) {
    return value;
  }
  if (userUnit === "f" && sensorUnit === "c") {
    return celsiusToFahrenheit(value).toFixed(2);
  }
  if (userUnit === "c" && sensorUnit === "f") {
    return fahrenheitToCelsius(value).toFixed(2);
  }
}

export function celsiusToFahrenheit(celsius: number) {
  return (celsius * 9) / 5 + 32;
}

export function fahrenheitToCelsius(fahrenheit: number) {
  return ((fahrenheit - 32) * 5) / 9;
}
